<template>
  <div>
    <div>
      <Header></Header>
    </div>

    <div id="forms_div2">
      <div style="position:absolute;width: 600px;height: 50px;background: #f2f4f7">
      </div>
      <div style="position:absolute;right: 0;width: 600px;height: 50px;background: #f2f4f7">
        <div style="float: right;margin-top: 2%;margin-right: 2%">
          <el-button type="danger" size="mini" @click="finish_up()">今日完成上报</el-button>
          <el-button type="success" size="mini" @click="goBackOnline()">返回</el-button>
        </div>
      </div>
      <iframe :src="online_document_url" width="100%" height="100%" style="border: medium none;"  id="a_frame"></iframe>
    </div>

  </div>
</template>

<script>
import Header from "@/views/header";
import {uuid} from "@/utils/time"
import {mapGetters} from "vuex";
import {ElMessage} from "element-plus";

export default {
  components: {Header},
  computed: {
    ...mapGetters(['GET_BDST'])
  },
  name: "view_online",
  data(){
    return{
      online_document_url: '',
      flag: ''
    }
  },
  created() {
    this.online_document_url = this.$route.params.online_document_url;
    this.flag = this.$route.params.flag;
  },
  mounted(){
    /**
     * iframe-宽高自适应显示
     */
    const oIframe = document.getElementById('a_frame');
    const deviceWidth = document.documentElement.clientWidth;
    const deviceHeight = document.documentElement.clientHeight;
    oIframe.style.width = (Number(deviceWidth)) + 'px'; //数字是页面布局宽度差值
    oIframe.style.height = (Number(deviceHeight)-85) + 'px'; //数字是页面布局高度差
  },
  methods:{
    goBackOnline(){
      const time = Date.parse(new Date()) + uuid();
      if (this.flag === '1'){
        this.$router.push({
          name: 'Online_document',
          params: {
            key: time,
          }
        });
      }
      if (this.flag === '2'){
        this.$router.push({
          name: 'Pro_online_document',
          params: {
            key: time,
          }
        });
      }
      if (this.flag === '3'){
        this.$router.push({
          name: 'Online_docu_manage',
          params: {
            key: time,
          }
        });
      }
    },
    finish_up(){
      this.axios.post('/onlineUpBdst/saveUpBdst', (response) => {
        if (response.obj){
          ElMessage({
            message: '上报成功!',
            type: 'success'
          })
        }else {
          ElMessage.error('上报失败，请联系科信部!')
        }
      },{
        bdst: this.GET_BDST
      })
    }
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}
#forms_div2{
  position: relative;
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  width: 100%;
  min-height: 92.5vh;
}
</style>
